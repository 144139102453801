<template>

  <div class="widget">

    <div class="widget-header">
      <div class="widget-header-label">
        <h3>{{ title }}</h3>
        <info-box :field="infoBox" />
      </div>
      <div class="widget-header-toolbar">
        <div class="actions">
          <!-- <div class="btn btn-grey btn-bold ml-3"><i class="fas fa-download"></i></div> -->
        </div>
      </div>
    </div>
    <div class="widget-body no-padding">

      <table class="vuetable">
        <thead>
          <tr>
            <th width="35%">{{ title }}</th>
            <th v-for="label in labels" :key="label">{{ label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ title }}</td>
            <td v-for="label in labels" :key="label">
                {{ years(label) }}
              </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>

import moment from 'moment';
import InfoBox from '@/components/InfoBox';

export default {
  data() {
    return {
      moment: moment
    }
  },
  props: {
    data: Array,
    title: String,
    yearsSelected: Array,
    infoBox: String,
    hideParent: Array,
  },
  components: {
    InfoBox,
  },
  computed: {
    labels() {
      let labels = [];
      
      this.data.forEach((value) => {
        if (this.hideParent.includes(value.key)) {
          return;
        }
        value.year.buckets.forEach((bucket) => {
           if (!labels.includes(bucket.key)) {
            if (this.yearsSelected.length) {
              if (this.yearsSelected.includes(bucket.key)) {
                labels.push(bucket.key);
              }
            } else {
              labels.push(bucket.key);
            }
          } 
        });
      });

      return labels;
    },
    
  },
  methods: {
    years(year) {
      let sum = 0;
      this.data.forEach((value) => {
        if (this.hideParent.includes(value.key)) {
          return;
        }
        value.year.buckets.forEach((bucket) => {
          if(bucket.key == year) {
            sum = sum + bucket.doc_count;
          }
        })
      });

      return sum;
    },
    capitalize(word) {
      return word.trim().replace(/^\w/, (c) => c.toUpperCase())
    },
    
    
  }
}
</script>
