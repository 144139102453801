<template>
	<layout-main>
		<div class="home">
			<!-- Header -->
			<div class="subheader">
				<div class="subheader-main">
					<h3>
						{{ $t('labels.statistics') }}
					</h3>
					<span class="separator-line"></span>
					<div class="breadcrumbs">
						<router-link to="/" class="breadcrumbs-link"> {{ $t('labels.home') }}</router-link>
						<span class="separator-dot"></span>
						<router-link to="/tickets/statistics" class="breadcrumbs-link"> {{ $t('labels.statistics')
						}}</router-link>
					</div>
				</div>
			</div>
			<!-- Header -->
			<reactive-base app="ticket_index" :credentials="user.user.es_username + ':' + user.user.es_password"
				:url="elasticUrl">

				<div class="row">
					<div class="col-12">
						<div class="widget">
							<div class="widget-header statistics-filter">
								<div class="widget-header-label">
									<h3>{{ $t('labels.selected-filters') }}</h3>

								</div>
								<div class="widget-header-toolbar">
									<div class="actions">
										<SelectedFilters class="bb-1 curent-filters pl-4 m-0"
											:clearAllLabel="this.$t('labels.clear')" />
									</div>
								</div>
							</div>
						</div>
					</div>
					<v-guard :permissions="['tickets-page-statistics-use-filter']">

						<div class="col-12">
							<div class="widget">
								<div class="widget-header statistics-filter">
									<multi-list componentId="yearFilter" :filterLabel="this.$t('labels.year')"
										:title="this.$t('labels.year')" dataField="year.raw" className="horizontal-filter"
										sortBy="asc" :showSearch="false" :URLParams="true"
										:react="{ and: getOtherFilterIds('yearFilter') }" @valueChange="setSelectedYears">

										<template slot="renderItem" slot-scope="{ label }">
											<div>{{ label }}</div>
										</template>
									</multi-list>

									<multi-list componentId="quarterFilter" :filterLabel="this.$t('labels.quarter')"
										:title="this.$t('labels.quarter')" dataField="quarter.raw"
										className="horizontal-filter" sortBy="asc" :showSearch="false" :URLParams="true"
										:react="{ and: getOtherFilterIds('quarterFilter') }">
										<template slot="renderItem" slot-scope="{ label }">
											<div>
												{{ label }}
											</div>
										</template>
									</multi-list>

									<multi-list componentId="monthFilter" :filterLabel="this.$t('labels.month')"
										:title="this.$t('labels.month')" dataField="month.raw" className="horizontal-filter"
										sortBy="asc" :size="12" :showSearch="false" :URLParams="true"
										:transformData="sortMonthByLabel"
										:react="{ and: getOtherFilterIds('monthFilter') }">
										<template slot="renderItem" slot-scope="{ label, count }">
											<div>
												{{ renameMonthsLabel(label) }}
											</div>
										</template>
									</multi-list>

								</div>
							</div>
						</div>

						<div class="col-lg-12 col-xl-3 order-lg-1 order-xl-1">
							<div class="widget widget-facets statistics-vertical">
								<div class="widget-header mb-3">
									<div class="widget-header-label">
										<h3><i class="fas fa-circle-notch fa-spin" v-show="isLoading"></i>{{
											$t('labels.filtration') }}</h3>
									</div>
									<div class="widget-header-toolbar">
										<div class="actions"></div>
									</div>
								</div>

								<div class="widget-body p-0">

									<multi-dropdown-list className="filter-vertical" :URLParams="true" :showSearch="true"
										:placeholder="this.$t('labels.select')" sortBy="asc"
										:title="this.$t('labels.gender')" componentId="genderFilter"
										:filterLabel="this.$t('labels.gender')" :dataField="this.$field('gender') + '.raw'"
										:react="{ and: getOtherFilterIds('genderFilter') }" />

								</div>

								<div class="widget-body p-0">

									<multi-dropdown-list className="filter-vertical" :URLParams="true" :showSearch="true"
										:placeholder="this.$t('labels.select')" sortBy="asc"
										:title="this.$t('labels.age-group')" componentId="ageFilter"
										:filterLabel="this.$t('labels.age-group')" :dataField="this.$field('age') + '.raw'"
										:transformData="customSortAge" :react="{ and: getOtherFilterIds('ageFilter') }" />

								</div>

								<div class="widget-body p-0">

									<multi-dropdown-list className="filter-vertical" :URLParams="true" :showSearch="true"
										:placeholder="this.$t('labels.select')" sortBy="asc" componentId="categoryFilter"
										:filterLabel="this.$t('labels.category')" :title="this.$t('labels.category')"
										:dataField="this.$field('category') + '.raw'"
										:react="{ and: getOtherFilterIds('categoryFilter') }" />

								</div>

								<div class="widget-body p-0">

									<multi-dropdown-list className="filter-vertical" :URLParams="true" :showSearch="true"
										:placeholder="this.$t('labels.select')" sortBy="asc"
										:title="this.$t('labels.first-contact')" componentId="firstContactFilter"
										:filterLabel="this.$t('labels.first-contact')"
										:dataField="this.$field('first_contact') + '.raw'"
										:react="{ and: getOtherFilterIds('firstContactFilter') }" />

								</div>

								<div class="widget-body p-0">

									<multi-dropdown-list className="filter-vertical" :URLParams="true" :showSearch="true"
										:placeholder="this.$t('labels.select')" sortBy="asc" componentId="crimeFilter"
										:size="300" :title="this.$t('labels.crime')" :filterLabel="this.$t('labels.crime')"
										:dataField="this.$field('crime') + '.raw'"
										:react="{ and: getOtherFilterIds('crimeFilter') }" />

								</div>

								<div class="widget-body p-0">

									<multi-dropdown-list className="filter-vertical" :URLParams="true" :showSearch="true"
										:placeholder="this.$t('labels.select')" sortBy="asc"
										:title="this.$t('labels.special-circumstances')"
										componentId="crimeRelationSubFilter"
										:filterLabel="this.$t('labels.special-circumstances')" nestedField="crime_relation"
										:dataField="this.$field('crime_relation.label') + '.raw'"
										:react="{ and: getOtherFilterIds('crimeRelationSubFilter') }" />

								</div>

								<div class="widget-body p-0">

									<multi-dropdown-list className="filter-vertical" :URLParams="true" :showSearch="true"
										:placeholder="this.$t('labels.select')" sortBy="asc"
										:title="this.$t('labels.special-events')" componentId="specialEventSubFilter"
										:filterLabel="this.$t('labels.special-events')"
										:dataField="this.$field('special_event') + '.raw'"
										:react="{ and: getOtherFilterIds('specialEventSubFilter') }" />

								</div>

								<div class="widget-body p-0">

									<multi-dropdown-list className="filter-vertical" :URLParams="true" :showSearch="true"
										:placeholder="this.$t('labels.select')" sortBy="asc" componentId="riskFilter"
										:title="this.$t('labels.increased-risk')" :filterLabel="this.$t('labels.risk')"
										:dataField="this.$field('risk') + '.raw'"
										:react="{ and: getOtherFilterIds('riskFilter') }" />

								</div>

								<div class="widget-body p-0">

									<multi-dropdown-list className="filter-vertical" :URLParams="true" :showSearch="true"
										:placeholder="this.$t('labels.select')" sortBy="asc" :size="1000"
										:title="this.$t('labels.language')" componentId="languageFilter"
										:filterLabel="this.$t('labels.language')"
										:dataField="this.$field('language') + '.raw'"
										:react="{ and: getOtherFilterIds('languageFilter') }" />

								</div>

								<div class="widget-body p-0">

									<multi-dropdown-list className="filter-vertical" :URLParams="true" :showSearch="true"
										:placeholder="this.$t('labels.select')" sortBy="asc" :size="1000"
										componentId="officeFilter" :title="this.$t('labels.victim-support-centre')"
										:filterLabel="this.$t('labels.victim-support-centre')" dataField="office.raw"
										:react="{ and: getOtherFilterIds('officeFilter') }" />

								</div>

								<div class="widget-body p-0">

									<multi-dropdown-list className="filter-vertical" :URLParams="true" :showSearch="true"
										:placeholder="this.$t('labels.select')" sortBy="asc" :size="1000"
										componentId="localofficeFilter" :filterLabel="this.$t('labels.local-office')"
										:title="this.$t('labels.local-office')" dataField="localoffice.raw"
										:react="{ and: getOtherFilterIds('localofficeFilter') }" />

								</div>

								<div class="widget-body p-0">

									<multi-dropdown-list className="filter-vertical" :URLParams="true" :showSearch="true"
										:placeholder="this.$t('labels.select')" sortBy="asc" :size="1000"
										componentId="municipalityFilter" :title="this.$t('labels.municipality')"
										:filterLabel="this.$t('labels.municipality')" dataField="municipality.raw"
										:react="{ and: getOtherFilterIds('municipalityFilter') }" />

								</div>

								<div class="widget-body p-0">

									<multi-dropdown-list className="filter-vertical" :URLParams="true" :showSearch="true"
										:placeholder="this.$t('labels.select')" sortBy="asc" :size="1000"
										componentId="userSubFilter" :title="this.$t('labels.administrator')"
										:filterLabel="this.$t('labels.administrator')" nestedField="users"
										dataField="users.name.raw" :react="{ and: getOtherFilterIds('userSubFilter') }" />

								</div>

							</div>
						</div>
					</v-guard>
					<div class="col-lg-12 col-xl-9 order-lg-1 order-xl-1">
						<reactive-component componentId="myColorPicker" :URLParams="true"
							:react="{ and: getOtherFilterIds('') }" :defaultQuery="() => ({
									runtime_mappings: {
										'date.month_of_years': {
											type: 'long',
											script: 'emit(doc[\'created_at\'].value.getMonthValue())'
										}
									},
									aggs: {
										crimes: {
											terms: {
												field: this.$field('crime') + '.raw',
												order: {
													_key: 'asc'
												},
												size: 100,
											},
											aggs: {
												year: {
													terms: {
														field: 'year.raw',
														order: {
															_key: 'asc'
														},
														min_doc_count: 0,
														size: 100,
													},
													aggs: {
														gender: {
															terms: {
																field: 'gender.raw',
																order: {
																	_key: 'asc'
																},
																size: 100,
															},
														},
													},
												},
											},
										},
										office: {
											terms: {
												field: 'office.raw',
												order: {
													_key: 'asc'
												},
												size: 100,
											},
											aggs: {
												year: {
													terms: {
														field: 'year.raw',
														order: {
															_key: 'asc'
														},
														min_doc_count: 0,
														size: 100,
													},
												},
											},
										},
										localoffice: {
											terms: {
												field: 'localoffice.raw',
												order: {
													_key: 'asc'
												},
												size: 100,
											},
											aggs: {
												year: {
													terms: {
														field: 'year.raw',
														order: {
															_key: 'asc'
														},
														min_doc_count: 0,
														size: 100,
													},
												},
											},
										},
										age: {
											terms: {
												field: this.$field('age') + '.raw',
												order: {
													_key: 'asc'
												},
											},
											aggs: {
												year: {
													terms: {
														field: 'year.raw',
														order: {
															_key: 'asc'
														},
														min_doc_count: 0,
														size: 100,
													},
												},
											},
										},
										gender: {
											terms: {
												field: this.$field('gender') + '.raw',
												order: {
													_key: 'asc'
												},
											},
											aggs: {
												year: {
													terms: {
														field: 'year.raw',
														order: {
															_key: 'asc'
														},
														min_doc_count: 0,
														size: 100,
													},
												},
											},
										},
										month: {
											terms: {
												field: 'date.month_of_years',
												order: {
													_key: 'asc'
												},
												size: 100,
											},
											aggs: {
												year: {
													terms: {
														script: 'if (doc[\'created_at\'].size() != 0) doc[\'created_at\'].value.getYear()',
														order: {
															_key: 'asc'
														},
														min_doc_count: 0,
														size: 100,
													}
												}
											}
										},
										quarter: {
											terms: {
												field: 'quarter.raw',
												order: {
													_key: 'asc'
												},
											},
											aggs: {
												year: {
													terms: {
														field: 'year.raw',
														order: {
															_key: 'asc'
														},
														min_doc_count: 0,
														size: 100,
													},
												},
											},
										},
										category: {
											terms: {
												field: this.$field('category') + '.raw',
												order: {
													_key: 'asc'
												},
											},
											aggs: {
												year: {
													terms: {
														field: 'year.raw',
														order: {
															_key: 'asc'
														},
														min_doc_count: 0,
														size: 100,
													},
												},
											},
										},
										language: {
											terms: {
												field: this.$field('language') + '.raw',
												order: {
													_key: 'asc'
												},
												size: 100,
											},
											aggs: {
												year: {
													terms: {
														field: 'year.raw',
														order: {
															_key: 'asc'
														},
														min_doc_count: 0,
														size: 100,
													},
												},
											},
										},
										municipality: {
											terms: {
												field: 'municipality.raw',
												order: {
													_key: 'asc'
												},
												size: 1000,
											},
											aggs: {
												year: {
													terms: {
														field: 'year.raw',
														order: {
															_key: 'asc'
														},
														min_doc_count: 0,
														size: 100,
													},
												},
											},
										},
										users: {
											nested: {
												path: 'users',
											},
											aggs: {
												user: {
													terms: {
														field: 'users.name.raw',
														order: {
															_key: 'asc'
														},
														size: 1000,
													},
													aggs: {
														reverted: {
															reverse_nested: {},
															aggs: {
																year: {
																	terms: {
																		field: 'year.raw',
																		order: {
																			_key: 'asc'
																		},
																		min_doc_count: 0,
																		size: 1000,
																	}
																}
															}
														}
													},
												},
											},
										},
										crime_relations: {
											nested: {
												path: 'crime_relation',
											},
											aggs: {
												relation: {
													terms: {
														field: 'crime_relation.label.raw',
														order: {
															_key: 'asc'
														},
														size: 100,
													},
													aggs: {
														reverted: {
															reverse_nested: {},
															aggs: {
																year: {
																	terms: {
																		field: 'year.raw',
																		order: {
																			_key: 'asc'
																		},
																		min_doc_count: 0,
																		size: 100,
																	}
																}
															}
														}
													},
												},
											},
										},
										firstcontact: {
											terms: {
												field: this.$field('first_contact') + '.raw',
												order: {
													_key: 'asc'
												},
											},
											aggs: {
												year: {
													terms: {
														field: 'year.raw',
														order: {
															_key: 'asc'
														},
														min_doc_count: 0,
														size: 100,
													},
												},
											},
										},
										special_event: {
											terms: {
												field: this.$field('special_event') + '.raw',
												order: {
													_key: 'asc'
												},
											},
											aggs: {
												year: {
													terms: {
														field: 'year.raw',
														order: {
															_key: 'asc'
														},
														min_doc_count: 0,
														size: 100,
													},
												},
											},
										},
									},
								})
								">
							<div slot-scope="{ aggregations, hits, setQuery }">

								<div class="row">
									<div class="col-12">
										<statistic-widget :yearsSelected="yearsSelected"
											:data="aggregations.quarter.buckets"
											:title="$t('labels.quarter')"></statistic-widget>
									</div>
									<div class="col-12">
										<statistic-widget :yearsSelected="yearsSelected" :data="aggregations.month.buckets"
											:title="$t('labels.month')"></statistic-widget>
									</div>
									<div class="col-12">
										<statistic-widget :yearsSelected="yearsSelected" :data="aggregations.gender.buckets"
											:title="$t('labels.gender')"></statistic-widget>
									</div>
									<div class="col-12">
										<statistic-widget :yearsSelected="yearsSelected"
											:data="customSortAge(aggregations.age.buckets)"
											:title="$t('labels.age-group')">></statistic-widget>
									</div>
									<div class="col-12">
										<statistic-widget :yearsSelected="yearsSelected"
											:data="aggregations.category.buckets"
											:title="$t('labels.category')"></statistic-widget>
									</div>
									<div class="col-12">
										<statistic-widget :yearsSelected="yearsSelected"
											:data="aggregations.firstcontact.buckets"
											:title="$t('labels.first-contact')">></statistic-widget>
									</div>
									<div class="col-12">
										<statistic-widget :yearsSelected="yearsSelected" :data="aggregations.crimes.buckets"
											:title="$t('labels.crime')">></statistic-widget>
									</div>
									<div class="col-12">
										<statistic-widget :yearsSelected="yearsSelected"
											:data="aggregations.crime_relations.relation.buckets"
											:title="$t('labels.special-circumstances')">></statistic-widget>
									</div>
									<div class="col-12">
										<statistic-widget :yearsSelected="yearsSelected"
											:data="aggregations.special_event.buckets"
											:title="$t('labels.special-events')"></statistic-widget>
									</div>
									<div class="col-12">
										<statistic-widget :yearsSelected="yearsSelected"
											:data="aggregations.language.buckets"
											:title="$t('labels.language')"></statistic-widget>
									</div>
									<div class="col-12">
										<statistic-widget :yearsSelected="yearsSelected" :data="aggregations.office.buckets"
											:title="$t('labels.victim-support-centre')"></statistic-widget>
									</div>
									<div class="col-12">
										<statistic-widget :yearsSelected="yearsSelected"
											:data="aggregations.localoffice.buckets"
											:title="$t('labels.local-office')"></statistic-widget>
									</div>
									<div class="col-12">
										<statistic-widget :yearsSelected="yearsSelected"
											:data="aggregations.municipality.buckets"
											:title="$t('labels.municipality')"></statistic-widget>
									</div>
									<div class="col-12">
										<statistic-widget :yearsSelected="yearsSelected"
											:data="aggregations.users.user.buckets"
											:infoBox="'Ärenden avd Statistik - aggregerat fält Handläggare rubrik'"
											:title="$t('labels.administrator')"></statistic-widget>
									</div>
									<!-- <div class="col-12">
										<statistic-widget-hierarchical :yearsSelected="yearsSelected" :data="aggregations.actions.action_parent.buckets" title="Händelser"></statistic-widget-hierarchical>
									</div>
									<div class="col-12">
										<statistic-widget-action-time :yearsSelected="yearsSelected" :data="aggregations.actiontime.buckets" title="Nedlagd tid"></statistic-widget-action-time>
									</div> -->
								</div>
							</div>
						</reactive-component>
					</div>
				</div>
			</reactive-base>
			<ScrollTopArrow />
		</div>
	</layout-main>
</template>

<script>
import LayoutMain from '@/views/Layouts/Main';
import _ from 'lodash';
import StatisticWidget from '@/components/widgets/old/StatisticWidget.vue';
import StatisticWidgetActionTime from '@/components/widgets/old/StatisticWidgetActionTime.vue';
import statisticWidgetHierarchical from '@/components/widgets/old/statisticWidgetHierarchical.vue';
import ScrollTopArrow from '@/components/ScrollToTop'
import InfoBox from '@/components/InfoBox';

export default {
	components: {
		LayoutMain,
		StatisticWidget,
		StatisticWidgetActionTime,
		statisticWidgetHierarchical,
		ScrollTopArrow,
		InfoBox
	},
	data() {
		return {
			elasticUrl: process.env.VUE_APP_API_ELASTIC,
			getSortBy: 'id',
			isLoading: false,
			allMonths: [
				'Jan',
				'Feb',
				'Mar',
				'Apr',
				'Maj',
				'Jun',
				'Jul',
				'Aug',
				'Sep',
				'Okt',
				'Nov',
				'Dec'
			],
			customAge: [
				'Barn 0-11',
				'Ungdom 12-17',
				'Ung vuxen 18-25',
				'Vuxen 26-64',
				'Äldre 65 +',
				'Uppgift saknas'
			],
			facets: [
				'yearFilter',
				'monthFilter',
				'quarterFilter',
				'ageFilter',
				'genderFilter',
				'riskFilter',
				'officeFilter',
				'crimeFilter',
				'categoryFilter',
				'languageFilter',
				'crimeRelationSubFilter',
				'municipalityFilter',
				'localofficeFilter',
				'userSubFilter',
				'firstContactFilter',
				'actionSubFilter',
				'specialEventSubFilter'
			],
			yearsSelected: []
		};
	},
	methods: {
		groupedResultsByCrime(data) {
		},
		renameMonthsLabel(label) {
			label = this.allMonths[this.allMonths.indexOf(label.slice(0, 3))] ?? '';

			return label;
		},
		sortMonthByLabel(data) {
			let months = data.slice().sort((a, b) => {
				return this.allMonths.indexOf(a.key.slice(0, 3)) - this.allMonths.indexOf(b.key.slice(0, 3));
			});

			return months;
		},
		customSortAge(data) {
			let newData = data.slice().sort((a, b) => {
				return this.customAge.indexOf(a.key) - this.customAge.indexOf(b.key);
			});

			return newData;
		},
		getOtherFilterIds(id) {
			let ids = this.facets.filter((f) => f !== id).map((f) => f);

			return ids;
		},
		setSelectedYears(y) {
			if (y !== undefined && y !== null) {
				this.yearsSelected = y;
			} else {
				this.yearsSelected = [];
			}
		},
	},
	computed: {
		user() {
			return this.$store.getters.user;
		},
	}
};
</script>


<style>
.level-1>th {
	padding-left: 1em;
}
</style>