<template>
	<div class="widget">

		<div class="widget-header">
			<div class="widget-header-label">
				<h3>{{ title }}</h3>

			</div>
			<div class="widget-header-toolbar">
				<div class="actions">
					<!-- <div class="btn btn-grey btn-bold ml-3"><i class="fas fa-download"></i></div> -->
				</div>
			</div>
		</div>
		<div class="widget-body no-padding">

			<table class="vuetable">
				<thead>
					<tr>
						<th width="35%">{{ title }}</th>
						<th v-for="label in labels" :key="label">{{ label }}</th>
					</tr>
				</thead>
				<tbody>

					<tr>
						<td>Timmar</td>
						<td v-for="time in actiontime" :key="time.key">
							{{ time.time.value == 0 ? '' : minutes_to_hhmm(time.time.value) }}
						</td>
					</tr>

				</tbody>
			</table>
		</div>
	</div>
</template>
<script>

import moment from 'moment';

export default {
	data() {
		return {
			moment: moment
		}
	},
	props: {
		data: Array,
		title: String,
		yearsSelected: Array
	},
	computed: {
		labels() {
			let labels = [];

			this.data.forEach((value) => {
				if (!labels.includes(value.key)) {

					if (this.yearsSelected.length) {

						if (this.yearsSelected.includes(value.key)) {
							labels.push(value.key);
						}
					} else {
						labels.push(value.key);
					}

				}
			});

			return labels.sort();
		},
		actiontime() {

			if (this.yearsSelected.length) {
				const data = this.data.filter((d) => {
					if (this.yearsSelected.includes(d.key)) {
						return d;
					}
				})

				return data;
			}

			return this.data;
		}
	},
	methods: {
		capitalize(word) {
			return word.trim().replace(/^\w/, (c) => c.toUpperCase())
		},
		minutes_to_hhmm(numberOfMinutes) {
			const duration = moment.duration(numberOfMinutes, 'minutes');

			const hh = (duration.years() * (365 * 24)) + (duration.months() * (30 * 24)) + (duration.days() * 24) + (duration.hours());

			const mm = duration.minutes();

			return hh + ':' + mm;
		}
	}
}
</script>